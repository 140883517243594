.signup-title {
  margin-bottom: 30px;
  font-size: medium;
}

.sign-up-landing-wrapper {
  background: url('./img/DSC_1312.JPG') fixed;
  background-size: cover;
  background-position: center top -350px;
  animation: fadeIn 5s;
}

.landing-sign-up-title-wrapper h2 {
  padding-top: 10px;
}

.signup-wrapper {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px 5px 10px;
  min-width: 260px;
  font-family: 'Open Sans', sans-serif;
}

.signup-wrapper .success-title h1 {
  font-size: 20px;
  text-align: center;
  color: #ffbb0f;
}

.signup-wrapper .success-list p {
  color: #ffbb0f;
  font-size: 14px;
  padding: 15px;
}

.signup-wrapper .success-account-icon {
  font-size: 15pc;
  color: #ffbb0f;
  text-align: center;
}

.signup-wrapper .selected-wrapper {
  width: 100%;
  text-align: center;
}

.signup-wrapper .simple-error-msg {
  color: white;
  background-color: red;
  width: 100%;
}

.signup-wrapper .shipper-selected,
.signup-wrapper .carrier-selected {
  width: 100%;
  color: #ffbb0f;
  padding: 15px 0 0 0;
  font-size: 25px;
}

.signup-wrapper p {
  color: white;
  width: 100%;
  text-align: center;
}

.signup-wrapper label {
  color: #ffbb0f;
  margin: 0;
  font-size: 14px;
  padding: 22px 0 0 0;
}

.signup-wrapper .sm-field {
  background: #323d46;
  border-radius: unset;
  border-bottom: none;
  border-right: none;
  border-left: none;
  border-top: none;
  height: 40px;
  color: white;
  margin-bottom: 5px;
}

.signup-wrapper .humber-input {
  text-align: left;
}

.signup-wrapper .sign-up-humber-btn-disabled,
.signup-wrapper .sign-up-humber-btn {
  width: 10pc;
  background: #ffbb0f;
  color: darkslategray;
  border: none;
  text-align: center;
  font-size: 14px;
  padding: 8px;
  cursor: pointer;
  margin: 40px auto;
  border-radius: 100px;
  outline: none;
}

.signup-wrapper .sign-up-humber-btn-disabled {
  background: darkslategray;
  color: gray;
  cursor: auto;
}

.signup-wrapper .sm-humber-btn {
  width: 7pc;
  background: transparent;
  color: white;
  border: 2px solid #ffbb0f;
  border-radius: 50px;
  text-align: center;
  font-size: 14px;
  padding: 6px auto 3px auto;
  margin: 20px auto;
  cursor: pointer;
}

.signup-wrapper .user-type-btn {
  background: transparent;
  cursor: pointer;
  color: #ffbb0f;
  font-size: 18px;
  width: 10pc;
  display: inline-block;
  outline: none;
  padding: 5px 0;
  margin: 9px 0;
}

.signup-wrapper .user-selected {
  display: inline-block;
  font-size: 18px;
  width: 10pc;
  color: #ffbb0f;
  border-bottom: 3px solid #ffbb0f;
  outline: none;
  margin: 9px 0;
  cursor: pointer;
  padding-bottom: 15px;
}

.signup-wrapper .reset-password {
  cursor: pointer;
  padding: 10px;
  color: #ffbb0f;
  font-size: 12px;
}

.signup-wrapper .fields {
  margin: 12px auto 0 auto;
  width: 90%;
}

.signup-wrapper .user-type-btn-wrapper {
  width: 100%;
  text-align: center;
}

.signup-wrapper .humber-input label {
  font-size: 12px;
}

.signup-wrapper .login-link {
  cursor: pointer;
  color: #6495ED;
  text-align: center;
  font-size: 17px;
  float: right;
  margin-top: 3px;
  outline: none;
}

.signup-wrapper .terms {
  color: white;
  margin: 20px 10px;
}

.signup-wrapper .terms .link {
  color: #6495ED;
  background: transparent;
  border: none;
  margin-left: 10px;
}

@media (min-width: 768px) {
  .signup-wrapper .login-humber-btn {
    width: 100%;
  }

  .signup-wrapper {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    padding-top: 10px;
  }

  .signup-wrapper .sm-custom-wrapper {
    display: inline-block;
    vertical-align: top;
    width: 30%;
  }

  .signup-wrapper .md-custom-wrapper {
    display: inline-block;
    vertical-align: top;
    width: 40%;
  }

  .signup-wrapper .md-custom-wrapper.cuit,
  .signup-wrapper .md-custom-wrapper.cel-number {
    width: 25%;
  }

  .signup-wrapper .md-custom-wrapper.company-name,
  .signup-wrapper .md-custom-wrapper.email {
    width: 55%;
  }

  .signup-wrapper .md-custom-wrapper.company-name p {
    margin-top: 47px;
    color: #ffbb0f;
  }

  .signup-wrapper .lg-custom-wrapper {
    width: 80%;
    margin: 0 auto;
  }

  .signup-wrapper .fields {
    margin: 12px auto;
  }

  .signup-wrapper .humber-input {
    margin: 0 10px;
  }

  .signup-wrapper .section-three-wrapper,
  .signup-wrapper .section-two-wrapper,
  .signup-wrapper .section-one-wrapper {
    margin: 5px;
    padding: 12px 5px;

    border-radius: 11px;
  }

  .signup-wrapper .section-three-wrapper p {
    padding: 2px;
  }


  .signup-wrapper .user-type-btn {
    color: #ffbb0f;
    cursor: pointer;
    display: inline-block;
    padding: 10px;
    outline: none;
    border-radius: 50px;
    margin: 0 30px;
    border: 1px solid #ffbb0f;
    background: transparent;
  }

  .signup-wrapper .user-selected {
    color: gray;
    cursor: pointer;
    display: inline-block;
    padding: 10px;
    outline: none;
    border-radius: 50px;
    margin: 0 30px;
    border: 1px solid #ffbb0f;
    background: #ffbb0f;
  }

  .signup-wrapper .user-type-btn:hover {
    background: #ffbb0f;
    color: gray;
  }
}

@media (min-width: 1224px) {
  .signup-wrapper-100 {
    height: 100vh;
  }

  .signup-wrapper {
    width: 66pc;
    margin: 0 auto;
  }

  .signup-wrapper .fields {
    margin: 12px auto;
    width: 78%;

  }
}