nav {
  background: black;
}

.menu {
  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
  background: black;
}

nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  background: black;
}

nav li a {
  display: block;
  padding: 20px 20px;
  text-decoration: none;
  color: lightgray;
  font-family: 'Reem Kufi', sans-serif;
}

nav li a:hover,
.menu-btn:hover {
  color: #ffbb0f;
  text-decoration: none;
}

.logo {
  display: block;
  float: left;
  padding: 0;
  text-decoration: none;
  width: 8pc;
  margin: 14px;
}

.logo-img {
  width: 100%;
}

/* menu icon */

.menu-icon {
  cursor: pointer;
  float: right;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}

.menu-icon .navicon {
  background: #ffbb0f;
  display: block;
  height: 2px;
  position: relative;
  transition: background .2s ease-out;
  width: 18px;
}

.menu-icon .navicon:before,
.menu-icon .navicon:after {
  background:#ffbb0f;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.menu-icon .navicon:before {
  top: 5px;
}

.menu-icon .navicon:after {
  top: -5px;
}

.menu-btn {
  display: none;
}

.menu-btn:checked ~ .menu {
  max-height: 340px;
}

.menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */

@media (min-width: 840px) {
  .menu {
    padding: 0;
  }
  
  .landing-items li {
    float: left;
  }
  .landing-items li a {
    padding: 20px 8px;
  }
  .menu {
    clear: none;
    float: right;
    max-height: none;
  }
  .menu-icon {
    display: none;
  }
  li a:hover,
  .menu-btn:hover {
    background-color: black;
  }
}

@media (min-width: 768px) {
  nav {
    height: 66px;
  }
}

@media (min-width: 1000px) {
  nav {
    height: 66px;
  }
  .landing-items li a {
    padding: 20px 20px;
  }
}
