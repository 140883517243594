.carrier-record-billing-view-wrapper {
  overflow-y: auto;
  width: 100%;
}

.carrier-record-billing-view-wrapper .file-download-icon {
  margin: 0 10px;
}

.carrier-record-billing-view-wrapper table tbody tr td {
  background: #1c2023;
}

.carrier-record-billing-view-wrapper table tbody tr td {
  text-align: left;
  padding: 5px 17px 5px 0;
  border-bottom: 1px solid gray;
}

.carrier-record-billing-view-wrapper table tbody tr td .open {
  color: #ffbb0f;
  font-size: 14px;
  text-align: right;
  cursor: pointer;
  margin: 0;
}

.carrier-record-billing-view-wrapper table tbody tr td .opened {
  color: #ffbb0f;
  font-size: 14px;
  text-align: right;
  cursor: pointer;
  margin: 0;
}

.settlement-shipment-header {
  padding: 5px 10px 0;
  border-bottom: 1px solid black;
}

.settlement-shipment .amounts, 
.settlement-shipment-header .amounts {
  text-align: right;
}

.settlement-shipment-header p {
  display: inline-block;
  padding: 0 10px;
  color: #ffbb0f;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.settlement-shipment {
  padding: 2px 10px;
  border-bottom: 1px solid black;
}

.settlement-shipment p {
  display: inline-block;
  padding: 0 10px;
  color: #a9a9a9;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: bottom;
}

.settlement-shipment-header p.fit-lg-content,
.settlement-shipment p.fit-lg-content {
  width: 12pc;
}

.settlement-shipment-header p.fit-md-content,
.settlement-shipment p.fit-md-content {
  width: 7pc;
}

.settlement-shipment-header p.fit-sm-content,
.settlement-shipment p.fit-sm-content {
  width: 5pc;
}

.carrier-record-billing-table-wrapper table {
  width: 130pc;
}

.carrier-record-billing-view-wrapper table tbody tr td .documents-icon {
  color: #6495ed;
  cursor: pointer;
}

.carrier-record-billing-table-wrapper .doc-settlements {
  z-index: 1000;
  position: absolute;
  top: 84px;
  left: 5%;
  width: 90%;
  height: 85vh;
  background: #1c2023;
  overflow: auto;
  border-radius: 20px;
  border: 1px solid #ffbb0f;
}

.carrier-record-billing-table-wrapper .doc-settlements h3 {
  color: #ffbb0f;
  padding: 3px 23px;
  cursor: pointer;
  text-align: right;
  font-weight: 300px;
}

.carrier-record-billing-table-wrapper .pdf-list-wrapper {
  background: white;
  overflow: auto;
  margin: 0 3%;
  height: 74vh;
}

.carrier-record-billing-table-wrapper .pdf-list-wrapper p {
  width: 100%;
  background: #343a40;
  color: #ffbb0f;
  padding: 6px 10px;
}

.carrier-record-billing-table-wrapper .settlement-wrap {
  height: 36pc;
  overflow: auto;
}

.carrier-record-billing-table-wrapper .pdf-list-wrapper .pdf-icon {
  margin: 0 5px;
  color: red
}