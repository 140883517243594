@import '../node_modules/antd/dist/antd.dark.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(28, 32, 35);

}

/* body.chakra-ui-light {
  background: rgb(28, 32, 35);
} */


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

nav {
  min-width: 240px;
}

p {
  margin: 0;
}