.success-screen {
  background: #1c2023;
  width: 100%;
  height: 100vh;
  text-align: center;
}

.success-screen .message h2 {
  color: #ffbb0f;
  font-size: 23px;
  text-align: center;
}

.success-screen .icon-wrapper {
  width: 100%;
  text-align: center;
}

.success-screen .icon {
  font-size: 14pc;
  color: #19c482;
  margin: 65px 0;
}

.success-screen .options-wrapper label .message-one-button,
.success-screen .options-wrapper label .message-two-button {
  background: #ffbb0f;
  width: 172px;
  outline: none;
  text-align: center;
  border-radius: 30px;
  font-size: 16px;
  padding: 7px;
  cursor: pointer;
  color: #1c2023;
  margin: 20px 0;
}

@media (min-width: 1366px) {
  .success-screen .message h2 {
    font-size: 40px;
    padding-top: 60px;
  }

  .success-screen .options-wrapper label .message-one-button,
  .success-screen .options-wrapper label .message-two-button {
    margin: 20px 50px;
  }
}

