.carrier-record-table-wrapper table {
  margin: 0 auto 2pc auto;
}

.carrier-record-table-wrapper {
  background: #1c2023;
  overflow-y: auto;
  width: 100%;
  height: calc(100vh - 60px);
}

.carrier-record-table-wrapper .loading {
  width: 100%;
  margin-left: 50%;
  border-bottom: 2px solid #ffbb0f;
}

.carrier-record-table-wrapper table thead {
  background: #343a40;
}

.carrier-record-table-wrapper table thead tr th {
  color: #ffbb0f;
  font-weight: 300;
  font-size: 12px;
  padding: 15px 20px;
  text-align: left;
  vertical-align: top;
}

.carrier-record-table-wrapper table thead tr th .filter-input input {
  background: #343a40;
  border: none;
  border-bottom: 1px solid #ffbb0f;
  outline: none;
  width: 100px;
  color: white;
}

.carrier-record-table-wrapper table tbody tr td {
  color: white;
  font-size: 12px;
  padding: 10px 20px;
  border-bottom: 1px solid #343a40;
}
