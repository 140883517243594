.carrier-record-billing-table-wrapper {
  background: #1c2023;
  width: 100%;
  padding-bottom: 45px;
}

.carrier-record-billing-table-wrapper table {
  width: 100%;
}

.carrier-record-billing-table-wrapper table thead {
  background: #343a40;
  color: gray;
}

.carrier-record-billing-table-wrapper table thead tr th {
  color: gray;
  font-weight: 400;
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
}

.carrier-record-billing-table-wrapper table tbody tr td {
  color: white;
  font-size: 12px;
}

.carrier-record-billing-table-wrapper table tbody tr.record-shipment td {
  padding: 0;
  
}
.carrier-record-billing-table-wrapper table tbody tr.record-shipment td .shipment {
  border-top: unset;
  background: #1c2023;
  margin-left: 60px;
}