.humber-dropdown label,
.humber-input label {
  color: #ffbb0f;
  width: 100%;
  font-size: 15px;
  margin: 0;
  padding: 22px 0 10px 0;
}

.humber-dropdown .dropdown *,
.humber-input label * {
  background: #323d46;
  color: white;
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: unset;
  border-bottom: none;
}

.humber-dropdown .dropdown {
  background: #323d46;
}

.humber-input input {
  height: 38px;
}

.humber-textarea {
  height: 200px;
}

.form-control:focus {
  background: #323d46;
  color: white;
}

.save-humber-btn,
.sm-humber-btn {
  width: 7pc;
  background: transparent;
  color: white;
  border: 2px solid #ffbb0f;
  border-radius: 50px;
  text-align: center;
  font-size: 14px;
  padding: 4px;
  margin: 10px auto;
  cursor: pointer;
  margin-bottom: 5%;
}

.save-humber-btn {
  margin-top: 15px;
  width: 20pc;
  padding: 17px;
  font-size: 18px;
  align-content: center;
}

.save-humber-btn-wrapper {
  width: 100%;
  text-align: center;
  align-content: center;
  height: 100px;
}

.error-msg {
  width: 100%;
  background: red;
  margin: 0 auto;
  padding: 4px 30px;
  color: white;
}

.succes-msg {
  width: 100%;
  background: #19c482;
  margin: 0 auto;
  padding: 4px 30px;
  color: white;
}

.simple-error-msg {
  color: red;
}

.title h2 {
  color: #ffbb0f;
  border-bottom: 1px solid #ffbb0f;
  font-weight: 300;
  font-size: 22px;
  text-align: left;
  padding: 10px;
}

.interaction {
  color: #19c482;
  float: left;
  width: 100%;
  margin-left: 1pc;
}

.ok-bar {
  background-color: #19c482;
  height: 15px;
  width: 54px;
  float: right;
  border-radius: 110px;
  font-size: 10px;
  text-align: center;
  color: black;
}