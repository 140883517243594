.login-wrapper {
  background: rgba(0, 0, 0, 1);
  padding: 1px 10px;
  border: 1px solid black;
}

.login-wrapper .fields {
  margin: 12px auto 0 auto;
  width: 90%;
}

.login-wrapper .user-type-btn-wrapper {
  width: 100%;
  text-align: center;
}

.login-wrapper .title h2 {
  color: #ffbb0f;
  font-weight: 300;
  font-size: 16px;
  text-align: left;
  padding: 10px;
}

.login-wrapper .login-humber-btn {
  width: 10pc;
  background: #ffbb0f;
  color: darkslategray;
  border: none;
  text-align: center;
  font-size: 14px;
  padding: 8px;
  cursor: pointer;
  margin: 40px auto;
  border-radius: 100px;
  outline: none;
}

.login-wrapper .user-type-btn {
  background: transparent;
  cursor: pointer;
  color: #ffbb0f;
  font-size: 12px;
  width: 7pc;
  display: inline-block;
  outline: none;
  padding: 5px 0;
  margin: 40px 0;
}

.login-wrapper .user-selected {
  display: inline-block;
  font-size: 12px;
  color: #ffbb0f;
  border-bottom: 3px solid #ffbb0f;
  outline: none;
  width: 7pc;
  margin: 40px 0;
  padding-bottom: 15px;
}

.login-wrapper .sm-humber-btn {
  width: 7pc;
  background: transparent;
  color: white;
  border: 2px solid #ffbb0f;
  border-radius: 50px;
  text-align: center;
  font-size: 14px;
  padding: 6px auto 3px auto;
  margin: 20px auto;
  cursor: pointer;
}

.login-wrapper .reset-password-btn {
  color: #ffbb0f;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  outline: none;
}

.login-wrapper .reset-password {
  cursor: pointer;
  color: #6495ED;
  font-size: 14px;
  margin-left: 19px;
}

.login-wrapper .register {
  cursor: pointer;
  color: #6495ED;
  width: 100%;
  text-align: center;
  font-size: 14px;
  outline: none;
}

.login-wrapper .text {
  margin: 40px;
}

.login-wrapper .text p {
  color: white;
  text-align: center;
}

.login-wrapper .login-error-msg {
  color: red;
  width: 100%;
  text-align: center;
  padding-top: 18px;
}

.login-wrapper .reset-password-msg {
  color: green;
}

.login-wrapper .humber-input label {
  font-size: 12px;
}

.login-wrapper .title h2 {
  font-size: 20px;
  margin: 0;
}
@media (min-width: 768px) {
  .login-wrapper {
    background-color: rgba(0, 0, 0, 0.8);
    width: 375px;
    margin: 0 auto;
    border-radius: 32px;
  }
  .login-wrapper .login-humber-btn {
    margin: 24px auto;
  }

  .login-wrapper .text{
    margin: 27px;
    font-size: 12px;
  }
}

@media only screen  and (min-width : 1824px) {
  .login-wrapper {
    top: 5pc;
    right: 30pc;
  }
}