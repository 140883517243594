.humber-selection-button ul li {
  display: block;
}

.humber-selection-button .option {
  cursor: pointer;
  background: #2f3345;
  border: 1px solid #2f3345;
  border-radius: 40px;
  color: #fff;
  font-size: 14px;
  padding: 8px 14px;
  margin: 10px;
  width: 90%;
  text-align: center;
}

.humber-selection-button .option:hover {
  border: 1px solid gray;
}

.humber-selection-button .option-selected {
  cursor: pointer;
  background: #2f3345;
  border-radius: 40px;
  border: 1px solid #ffbb0f;
  color: #ffbb0f;
  font-size: 14px;
  padding: 8px 14px;
  margin: 10px;
  width: 90%;
  text-align: center;
}

.humber-selection-button input {
  display: none;
}

@media (min-width: 768px) {
  
  .humber-selection-button ul li {
    display: inline-block;
  }

  .humber-selection-button .option-selected,
  .humber-selection-button .option {
    padding: 3px 14px;
    width: unset;
  }

  
}
