@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(28, 32, 35);

}

/* body.chakra-ui-light {
  background: rgb(28, 32, 35);
} */


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

nav {
  min-width: 240px;
}

p {
  margin: 0;
}
.login-wrapper {
  background: rgba(0, 0, 0, 1);
  padding: 1px 10px;
  border: 1px solid black;
}

.login-wrapper .fields {
  margin: 12px auto 0 auto;
  width: 90%;
}

.login-wrapper .user-type-btn-wrapper {
  width: 100%;
  text-align: center;
}

.login-wrapper .title h2 {
  color: #ffbb0f;
  font-weight: 300;
  font-size: 16px;
  text-align: left;
  padding: 10px;
}

.login-wrapper .login-humber-btn {
  width: 10pc;
  background: #ffbb0f;
  color: darkslategray;
  border: none;
  text-align: center;
  font-size: 14px;
  padding: 8px;
  cursor: pointer;
  margin: 40px auto;
  border-radius: 100px;
  outline: none;
}

.login-wrapper .user-type-btn {
  background: transparent;
  cursor: pointer;
  color: #ffbb0f;
  font-size: 12px;
  width: 7pc;
  display: inline-block;
  outline: none;
  padding: 5px 0;
  margin: 40px 0;
}

.login-wrapper .user-selected {
  display: inline-block;
  font-size: 12px;
  color: #ffbb0f;
  border-bottom: 3px solid #ffbb0f;
  outline: none;
  width: 7pc;
  margin: 40px 0;
  padding-bottom: 15px;
}

.login-wrapper .sm-humber-btn {
  width: 7pc;
  background: transparent;
  color: white;
  border: 2px solid #ffbb0f;
  border-radius: 50px;
  text-align: center;
  font-size: 14px;
  padding: 6px auto 3px auto;
  margin: 20px auto;
  cursor: pointer;
}

.login-wrapper .reset-password-btn {
  color: #ffbb0f;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  outline: none;
}

.login-wrapper .reset-password {
  cursor: pointer;
  color: #6495ED;
  font-size: 14px;
  margin-left: 19px;
}

.login-wrapper .register {
  cursor: pointer;
  color: #6495ED;
  width: 100%;
  text-align: center;
  font-size: 14px;
  outline: none;
}

.login-wrapper .text {
  margin: 40px;
}

.login-wrapper .text p {
  color: white;
  text-align: center;
}

.login-wrapper .login-error-msg {
  color: red;
  width: 100%;
  text-align: center;
  padding-top: 18px;
}

.login-wrapper .reset-password-msg {
  color: green;
}

.login-wrapper .humber-input label {
  font-size: 12px;
}

.login-wrapper .title h2 {
  font-size: 20px;
  margin: 0;
}
@media (min-width: 768px) {
  .login-wrapper {
    background-color: rgba(0, 0, 0, 0.8);
    width: 375px;
    margin: 0 auto;
    border-radius: 32px;
  }
  .login-wrapper .login-humber-btn {
    margin: 24px auto;
  }

  .login-wrapper .text{
    margin: 27px;
    font-size: 12px;
  }
}

@media only screen  and (min-width : 1824px) {
  .login-wrapper {
    top: 5pc;
    right: 30pc;
  }
}
.humber-dropdown label,
.humber-input label {
  color: #ffbb0f;
  width: 100%;
  font-size: 15px;
  margin: 0;
  padding: 22px 0 10px 0;
}

.humber-dropdown .dropdown *,
.humber-input label * {
  background: #323d46;
  color: white;
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: unset;
  border-bottom: none;
}

.humber-dropdown .dropdown {
  background: #323d46;
}

.humber-input input {
  height: 38px;
}

.humber-textarea {
  height: 200px;
}

.form-control:focus {
  background: #323d46;
  color: white;
}

.save-humber-btn,
.sm-humber-btn {
  width: 7pc;
  background: transparent;
  color: white;
  border: 2px solid #ffbb0f;
  border-radius: 50px;
  text-align: center;
  font-size: 14px;
  padding: 4px;
  margin: 10px auto;
  cursor: pointer;
  margin-bottom: 5%;
}

.save-humber-btn {
  margin-top: 15px;
  width: 20pc;
  padding: 17px;
  font-size: 18px;
  align-content: center;
}

.save-humber-btn-wrapper {
  width: 100%;
  text-align: center;
  align-content: center;
  height: 100px;
}

.error-msg {
  width: 100%;
  background: red;
  margin: 0 auto;
  padding: 4px 30px;
  color: white;
}

.succes-msg {
  width: 100%;
  background: #19c482;
  margin: 0 auto;
  padding: 4px 30px;
  color: white;
}

.simple-error-msg {
  color: red;
}

.title h2 {
  color: #ffbb0f;
  border-bottom: 1px solid #ffbb0f;
  font-weight: 300;
  font-size: 22px;
  text-align: left;
  padding: 10px;
}

.interaction {
  color: #19c482;
  float: left;
  width: 100%;
  margin-left: 1pc;
}

.ok-bar {
  background-color: #19c482;
  height: 15px;
  width: 54px;
  float: right;
  border-radius: 110px;
  font-size: 10px;
  text-align: center;
  color: black;
}
.signup-title {
  margin-bottom: 30px;
  font-size: medium;
}

.sign-up-landing-wrapper {
  background: url(/static/media/DSC_1312.4b3e2b1f.JPG) fixed;
  background-size: cover;
  background-position: center top -350px;
  -webkit-animation: fadeIn 5s;
          animation: fadeIn 5s;
}

.landing-sign-up-title-wrapper h2 {
  padding-top: 10px;
}

.signup-wrapper {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px 5px 10px;
  min-width: 260px;
  font-family: 'Open Sans', sans-serif;
}

.signup-wrapper .success-title h1 {
  font-size: 20px;
  text-align: center;
  color: #ffbb0f;
}

.signup-wrapper .success-list p {
  color: #ffbb0f;
  font-size: 14px;
  padding: 15px;
}

.signup-wrapper .success-account-icon {
  font-size: 15pc;
  color: #ffbb0f;
  text-align: center;
}

.signup-wrapper .selected-wrapper {
  width: 100%;
  text-align: center;
}

.signup-wrapper .simple-error-msg {
  color: white;
  background-color: red;
  width: 100%;
}

.signup-wrapper .shipper-selected,
.signup-wrapper .carrier-selected {
  width: 100%;
  color: #ffbb0f;
  padding: 15px 0 0 0;
  font-size: 25px;
}

.signup-wrapper p {
  color: white;
  width: 100%;
  text-align: center;
}

.signup-wrapper label {
  color: #ffbb0f;
  margin: 0;
  font-size: 14px;
  padding: 22px 0 0 0;
}

.signup-wrapper .sm-field {
  background: #323d46;
  border-radius: unset;
  border-bottom: none;
  border-right: none;
  border-left: none;
  border-top: none;
  height: 40px;
  color: white;
  margin-bottom: 5px;
}

.signup-wrapper .humber-input {
  text-align: left;
}

.signup-wrapper .sign-up-humber-btn-disabled,
.signup-wrapper .sign-up-humber-btn {
  width: 10pc;
  background: #ffbb0f;
  color: darkslategray;
  border: none;
  text-align: center;
  font-size: 14px;
  padding: 8px;
  cursor: pointer;
  margin: 40px auto;
  border-radius: 100px;
  outline: none;
}

.signup-wrapper .sign-up-humber-btn-disabled {
  background: darkslategray;
  color: gray;
  cursor: auto;
}

.signup-wrapper .sm-humber-btn {
  width: 7pc;
  background: transparent;
  color: white;
  border: 2px solid #ffbb0f;
  border-radius: 50px;
  text-align: center;
  font-size: 14px;
  padding: 6px auto 3px auto;
  margin: 20px auto;
  cursor: pointer;
}

.signup-wrapper .user-type-btn {
  background: transparent;
  cursor: pointer;
  color: #ffbb0f;
  font-size: 18px;
  width: 10pc;
  display: inline-block;
  outline: none;
  padding: 5px 0;
  margin: 9px 0;
}

.signup-wrapper .user-selected {
  display: inline-block;
  font-size: 18px;
  width: 10pc;
  color: #ffbb0f;
  border-bottom: 3px solid #ffbb0f;
  outline: none;
  margin: 9px 0;
  cursor: pointer;
  padding-bottom: 15px;
}

.signup-wrapper .reset-password {
  cursor: pointer;
  padding: 10px;
  color: #ffbb0f;
  font-size: 12px;
}

.signup-wrapper .fields {
  margin: 12px auto 0 auto;
  width: 90%;
}

.signup-wrapper .user-type-btn-wrapper {
  width: 100%;
  text-align: center;
}

.signup-wrapper .humber-input label {
  font-size: 12px;
}

.signup-wrapper .login-link {
  cursor: pointer;
  color: #6495ED;
  text-align: center;
  font-size: 17px;
  float: right;
  margin-top: 3px;
  outline: none;
}

.signup-wrapper .terms {
  color: white;
  margin: 20px 10px;
}

.signup-wrapper .terms .link {
  color: #6495ED;
  background: transparent;
  border: none;
  margin-left: 10px;
}

@media (min-width: 768px) {
  .signup-wrapper .login-humber-btn {
    width: 100%;
  }

  .signup-wrapper {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    padding-top: 10px;
  }

  .signup-wrapper .sm-custom-wrapper {
    display: inline-block;
    vertical-align: top;
    width: 30%;
  }

  .signup-wrapper .md-custom-wrapper {
    display: inline-block;
    vertical-align: top;
    width: 40%;
  }

  .signup-wrapper .md-custom-wrapper.cuit,
  .signup-wrapper .md-custom-wrapper.cel-number {
    width: 25%;
  }

  .signup-wrapper .md-custom-wrapper.company-name,
  .signup-wrapper .md-custom-wrapper.email {
    width: 55%;
  }

  .signup-wrapper .md-custom-wrapper.company-name p {
    margin-top: 47px;
    color: #ffbb0f;
  }

  .signup-wrapper .lg-custom-wrapper {
    width: 80%;
    margin: 0 auto;
  }

  .signup-wrapper .fields {
    margin: 12px auto;
  }

  .signup-wrapper .humber-input {
    margin: 0 10px;
  }

  .signup-wrapper .section-three-wrapper,
  .signup-wrapper .section-two-wrapper,
  .signup-wrapper .section-one-wrapper {
    margin: 5px;
    padding: 12px 5px;

    border-radius: 11px;
  }

  .signup-wrapper .section-three-wrapper p {
    padding: 2px;
  }


  .signup-wrapper .user-type-btn {
    color: #ffbb0f;
    cursor: pointer;
    display: inline-block;
    padding: 10px;
    outline: none;
    border-radius: 50px;
    margin: 0 30px;
    border: 1px solid #ffbb0f;
    background: transparent;
  }

  .signup-wrapper .user-selected {
    color: gray;
    cursor: pointer;
    display: inline-block;
    padding: 10px;
    outline: none;
    border-radius: 50px;
    margin: 0 30px;
    border: 1px solid #ffbb0f;
    background: #ffbb0f;
  }

  .signup-wrapper .user-type-btn:hover {
    background: #ffbb0f;
    color: gray;
  }
}

@media (min-width: 1224px) {
  .signup-wrapper-100 {
    height: 100vh;
  }

  .signup-wrapper {
    width: 66pc;
    margin: 0 auto;
  }

  .signup-wrapper .fields {
    margin: 12px auto;
    width: 78%;

  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes bounce {
  from {
    bottom: 4pc;
  }

  to {
    bottom: 5pc;
  }
}

@keyframes bounce {
  from {
    bottom: 4pc;
  }

  to {
    bottom: 5pc;
  }
}

.landing-page {
  background: #1c2023;
  font-family: 'Open Sans', sans-serif;
}

.landing-page .section-one {
  background: url(/static/media/DSC_1329_rotada.7134ce7a.jpg) fixed;
  background-position: center bottom;
  height: 10pc;
  -webkit-animation: fadeIn 5s;
          animation: fadeIn 5s;
}

.landing-page .landing-logo {
  float: right;
  width: 140px;
  margin: 20px 30px;
}

.landing-page .phrase-wrapper {
  position: absolute;
  margin: 5pc 1pc 0 1pc;
  right: 0;
  text-align: right;
  -webkit-animation: fadeIn 9s;
          animation: fadeIn 9s;
}

.landing-page h1.phrase {
  font-family: 'Merriweather Sans', sans-serif;
  color: white;
  font-size: 24px;
}

.landing-page h2.sub-phrase {
  font-family: 'Merriweather Sans', sans-serif;
  color: white;
  font-size: 14px;
}

.landing-page .arrow-wrapper {
  display: none;
}

.landing-page .arrow-icon {
  position: absolute;
  color: white;
  font-size: 40px;
  bottom: 6pc;
}

.landing-page .humber-btn-wrapper {
  position: absolute;
  width: 200px;
  margin: 10pc 1pc 0 1pc;
  right: 0;
}

.landing-page .humber-btn {
  width: 164px;
  cursor: pointer;
  float: right;
  text-align: center;
  padding: 7px;
  border: 1px solid #ffbb0f;
  color: white;
  margin: 8px;
  border-radius: 50px;
  outline: none;
}

.landing-page .login-landing-wrapper {
  width: 100%;
  margin: 0 auto;
}

.landing-page .section-two .phrase {
  text-align: center;
  padding: 20px;
  background: #1c2023;
  color: #ffbb0f;
}

.landing-page .items h4 {
  color: #ffbb0f;
  font-size: 20px;
  text-align: center;
}

.landing-page .items {
  padding: 10px;
  color: #1c2023;
  background: white;
  font-size: 24px;
}

.landing-page .items p {
  font-size: 16px;
  color: #1c2023;
  text-align: center;
}

.landing-page .items .item {
  padding: 25px;
  width: 97%;
  margin: 28px auto;
  height: 290px;
}

.landing-page .section-two .truck-img {
  display: none;
}

.landing-page .items .item .img-wrapper {
  width: 120px;
  margin: 0 auto;
}

.landing-page .items .item .img-wrapper img {
  width: 100%;
  padding: 18px 0;
}

.landing-page .section-three .phrase {
  text-align: center;
  padding: 20px;
  color: #ffbb0f;
}

.landing-page .section-three .img-wrapper {
  width: 200px;
  margin: 0 auto;
}

.landing-page .section-three .img-wrapper img {
  width: 100%;
}

.landing-page .section-three .phrase h3 {
  color: #ffbb0f;
}

.landing-page .section-three .item-icon {
  display: inline-block;
  width: 10%;
  color: #ffbb0f;
  vertical-align: top;
  margin-top: 42px;
}

.landing-page .section-three .user-type {
  background: #ffbb0f;
  color: gray;
  text-align: center;
  padding: 12px;
  font-size: 20px;
}

.landing-page .section-three .shipper-section p,
.landing-page .section-three .carrier-section p {
  display: inline-block;
  width: 90%;
  color: #1c2023;
  text-align: left;
  margin: 40px 0;
}

.landing-page .footer {
  height: 200px;
}

.landing-page .footer p {
  margin-top: 36px;
}

.landing-page .footer .footer-logo-wrapper {
  width: 180px;
  margin: 20px auto;
}

.landing-page .section-three .laptop-img {
  display: none;
}

.landing-page .register-items {
  display: none;
}

.landing-page .landing-sign-up-title-wrapper {
  background: url(/static/media/registrate.15bea5f6.jpg) fixed;
  background-size: cover;
  background-position: center top;
  height: 4pc;
  -webkit-animation: fadeIn 5s;
          animation: fadeIn 5s;
  text-align: center;
}

.landing-page .landing-sign-up-title-wrapper h2 {
  padding-top: 10px;
}

.landing-page .option {
  display: inline-block;
  font-size: 14px;
  border-radius: 50px;
  cursor: pointer;
  color: #ffbb0f;
  padding: 14px;
  width: 40%;
  margin: 0 10px;
  text-align: center;
  outline: none;
  border: 1px solid;
}

.landing-page .option-selected {
  background: #ffbb0f;
  padding-right: 15px;
  color: gray;
  font-size: 14px;
  display: inline-block;
  border-radius: 50px;
  cursor: pointer;
  padding: 14px;
  width: 40%;
  margin: 0 10px;
  text-align: center;
  outline: none;
}

.landing-page .section-three .landing-buttons-wrapper {
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
}

.landing-page .section-three .landing-buttons-wrapper .landing-btn {
  display: inline-block;
  border-radius: 50px;
  cursor: pointer;
  color: #ffbb0f;
  padding: 14px;
  width: 30%;
  margin: 0 10px;
  text-align: center;
  outline: none;
  border: 1px solid;
}

.landing-page .hidden-input {
  visibility: hidden;
}

.landing-page .humber-btn-list {
  padding: 10px;
  display: inline;
}

@font-face {
  font-family: ETmodules;
  font-display: block;
  src: url(/fonts/modules.eot);
  src: url(/fonts/modules.eot?#iefix) format("embedded-opentype"), url(/fonts/modules.ttf) format("truetype"), url(/fonts/modules.ttf) format("woff"), url(/fonts/modules.svg#ETmodules) format("svg");
  font-weight: 400;
  font-style: normal
}

#et-info-phone:before {
  content: "\e090";
  position: relative;
  margin-right: 4px;
  font-family: ETmodules !important;
  font-style: normal;
  font-weight: 400;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 0 0;
  direction: ltr;
  top: 2px;
}

#et-info-mail:before {
  content: "\e076";
  position: relative;
  margin-right: 4px;
  font-family: ETmodules !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 0 0;
  direction: ltr
}

.phoneNumber {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.landing-page .footer p {
  color: #ffbb0f;
}

@media (min-width: 768px) {
  .landing-page .login-landing-wrapper {
    position: absolute;
    top: 9pc;
  }

  .landing-page .sign-up-landing-wrapper {
    background: url(/static/media/bgRegister.2d2bdc97.jpg) fixed;
    background-size: cover;
    background-position: center top;
    -webkit-animation: fadeIn 5s;
            animation: fadeIn 5s;
  }

  .landing-page .section-one {
    background: url(/static/media/DSC_1329_rotada.7134ce7a.jpg) fixed;
    background-size: cover;
    background-position: center;
    height: 62pc;
  }

  .landing-page .section-two {
    background: white;
  }

  .landing-page .section-three {
    background: white;
  }

  .landing-page .section-three .items {
    width: 45%;
    display: inline-block;
    padding: 30px;
  }

  .landing-page .section-three .item-icon {
    width: 6%;
    margin-right: 10px;
  }

  .landing-page .section-three .shipper-section {
    width: 95%;
    margin: 0 auto;
  }

  .landing-page .section-three .shipper-section p,
  .landing-page .section-three .carrier-section p {
    width: 90%;
  }

  .landing-page .section-three .laptop-img {
    display: block;
    width: 44%;
    float: right;
    padding-top: 12pc;
  }

  .landing-page .section-three .laptop-img img {
    width: 100%;
  }

  .landing-page .landing-logo {
    float: left;
    width: 120px;
    margin: 20px 30px;
  }

  .landing-page .humber-btn-wrapper {
    position: absolute;
    width: 42pc;
    margin: 20px 13pc 0 0;
    text-align: center;
  }

  .landing-page .arrow-wrapper {
    display: block;
    cursor: pointer;
    bottom: 10px;
    position: absolute;
    width: 100%;
    text-align: center;
  }

  .landing-page .items {
    background: transparent;
  }

  .landing-page .phrase-wrapper {
    width: 100%;
    text-align: center;
  }

  .landing-page .section-three .content {
    width: 48pc;
    margin: 0 auto;
  }

  .landing-page .section-three .user-type {
    color: gray;
    background: transparent;
    text-align: center;
    padding: 12px;
    font-size: 5pc;
  }
}

@media only screen and (min-width : 1224px) {
  .landing-page .phrase-wrapper {
    width: 50%;
    text-align: right;
    left: 0;
    top: 10pc;
  }

  .landing-page .login-landing-wrapper {
    right: 0;
    padding-top: 0;
    width: 50%;
  }

  .landing-page .section-two {
    background: url(/static/media/bgPorque.25d0d893.jpg) fixed -1000px;
    background-size: cover;
    background-position: center;
    height: 62pc;
  }

  .landing-page .section-two {
    margin: 0 auto;
  }

  .landing-page .section-two h1 {
    font-size: 50px;
    font-weight: bolder;
    width: 100%;
    text-align: center;
  }

  .landing-page .items {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding-top: 100px;
  }

  .landing-page .items .item {
    display: inline-grid;
    border-top-right-radius: 46px;
    border-bottom-left-radius: 72px;
    margin: 10px;
    height: 400px;
    background: white;
    width: 270px;
    box-shadow: -8px 5px 10px #888888;
  }

  .landing-page .items .item .img-wrapper {
    width: 120px;
    margin: 0 auto;
  }

  .landing-page .items .item .img-wrapper img {
    width: 100%;
    padding: 18px 0;
  }

  .landing-page .items .item h4 {
    height: 41px;
    text-align: center;
    padding: 10px;
    font-size: 22px;
  }

  .landing-page .items .item p {
    color: gray;
  }

  .landing-page .humber-btn-desktop {
    display: block;
  }

  .landing-page .humber-btn {
    display: none;
  }

  .landing-page h2.sub-phrase,
  .landing-page h1.phrase {
    font-size: 40px;
  }

  .landing-page .register-items {
    display: block;
    width: 48%;
    padding-top: 12pc;
    float: right;
  }

  .landing-page .register-items p {
    color: white;
    display: inline-block;
    width: 90%;
    font-size: 22px;
  }

  .landing-page .register-items .item-icon {
    color: #B64DEB;
    display: inline-block;
    width: 10%;
    font-size: 20px;
  }

  .landing-page .option,
  .landing-page .option-selected {
    width: 30%;
  }
}

@media only screen and (min-width : 1824px) {
  .landing-page .section-one h1.phrase {
    font-size: 50px;
    width: 45pc;
  }

  .landing-page .section-one h2.sub-phrase {
    font-size: 50px;
    width: 45pc;
  }

  .landing-page .items {
    padding-top: 9pc;
  }
}
nav {
  background: black;
}

.menu {
  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}

li a {
  display: block;
  padding: 20px 20px;
  text-decoration: none;
  color: lightgray;
  font-family: 'Reem Kufi', sans-serif;
}

li a:hover,
.menu-btn:hover {
  background-color: grey;
}

.logo {
  display: block;
  float: left;
  padding: 0;
  text-decoration: none;
  width: 8pc;
  margin: 14px;
}

.logo-img {
  width: 100%;
}

/* menu icon */

.menu-icon {
  cursor: pointer;
  float: right;
  padding: 28px 20px;
  position: relative;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.menu-icon .navicon {
  background: #ffbb0f;
  display: block;
  height: 2px;
  position: relative;
  transition: background .2s ease-out;
  width: 18px;
}

.menu-icon .navicon:before,
.menu-icon .navicon:after {
  background:#ffbb0f;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.menu-icon .navicon:before {
  top: 5px;
}

.menu-icon .navicon:after {
  top: -5px;
}

.menu-btn {
  display: none;
}

.menu-btn:checked ~ .menu {
  max-height: 240px;
}

.menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.menu-btn:checked ~ .menu-icon .navicon:before {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.menu-btn:checked ~ .menu-icon .navicon:after {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */

@media (min-width: 48em) {
  .menu {
    padding: 0;
  }
  .logo {
    width: 10pc;
  }
  .landing-items li {
    float: left;
  }
  .landing-items li a {
    padding: 20px 30px;
  }
  .menu {
    clear: none;
    float: right;
    max-height: none;
  }
  .menu-icon {
    display: none;
  }
  li a:hover,
  .menu-btn:hover {
    background-color: black;
  }
}

@media (min-width: 768px) {
  nav {
    height: 50px;
  }
}

.humber-selection-button ul li {
  display: block;
}

.humber-selection-button .option {
  cursor: pointer;
  background: #2f3345;
  border: 1px solid #2f3345;
  border-radius: 40px;
  color: #fff;
  font-size: 14px;
  padding: 8px 14px;
  margin: 10px;
  width: 90%;
  text-align: center;
}

.humber-selection-button .option:hover {
  border: 1px solid gray;
}

.humber-selection-button .option-selected {
  cursor: pointer;
  background: #2f3345;
  border-radius: 40px;
  border: 1px solid #ffbb0f;
  color: #ffbb0f;
  font-size: 14px;
  padding: 8px 14px;
  margin: 10px;
  width: 90%;
  text-align: center;
}

.humber-selection-button input {
  display: none;
}

@media (min-width: 768px) {
  
  .humber-selection-button ul li {
    display: inline-block;
  }

  .humber-selection-button .option-selected,
  .humber-selection-button .option {
    padding: 3px 14px;
    width: unset;
  }

  
}

.loading-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.success-screen {
  background: #1c2023;
  width: 100%;
  height: 100vh;
  text-align: center;
}

.success-screen .message h2 {
  color: #ffbb0f;
  font-size: 23px;
  text-align: center;
}

.success-screen .icon-wrapper {
  width: 100%;
  text-align: center;
}

.success-screen .icon {
  font-size: 14pc;
  color: #19c482;
  margin: 65px 0;
}

.success-screen .options-wrapper label .message-one-button,
.success-screen .options-wrapper label .message-two-button {
  background: #ffbb0f;
  width: 172px;
  outline: none;
  text-align: center;
  border-radius: 30px;
  font-size: 16px;
  padding: 7px;
  cursor: pointer;
  color: #1c2023;
  margin: 20px 0;
}

@media (min-width: 1366px) {
  .success-screen .message h2 {
    font-size: 40px;
    padding-top: 60px;
  }

  .success-screen .options-wrapper label .message-one-button,
  .success-screen .options-wrapper label .message-two-button {
    margin: 20px 50px;
  }
}


.ant-form-item {
    margin-bottom: 5px;
}

.shipment {
  background: black;
  border-top: 1px solid;
  margin: 0 10px;
  padding: 2px 10px;
}

.shipment p {
  font-size: 14px;
  display: inline-block;
  margin-right: 10px;
  color: darkgray;
}

.shipment .cellphone-icon,
.shipment .address-card-icon,
.shipment .user-icon {
  color: gray;
  margin: 0 17px;
  font-size: 22px;
}

.shipment .company-name {
  width: 190px;
  vertical-align: middle;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.shipment .user-icon-cancelled {
  color: red;
  font-size: 10px;
  padding-right: 10px;
}

.shipment .cellphone-icon,
.shipment .address-card-icon {
  font-size: 15px;
}

.shipment .trucker-name {
  width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: middle;
}

.shipment .truck-make,
.shipment .truck-type {
  width: 155px;
}

.shipment .trucker-cellphone {
  width: 127px;
}

.shipment .trailer-patent,
.shipment .trucker-patent {
  border: 1px solid #d3d3d3;
  border-top: 5px solid #09f;
  border-radius: 3px;
  text-align: center;
  width: 90px;
  margin-bottom: 5px;
}

.shipment .trucker-cuit {
  width: 82px;
}

.shipment .premium-doc,
.shipment .folder-icon {
  cursor: pointer;
  color: gray;
  padding: 0 10px;
}

.shipment .premium-doc:hover,
.shipment .folder-icon:hover {
  color: #6495ed;
}

.shipment .imgs-popup {
  width: 90%;
}

.shipment .doc-premium {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 600px;
  background: gray;
}

.shipment .doc-premium div {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.shipment .loading {
  height: 600px;
  background: gray;
}

.shipment .doc-premium .no-photo {
  text-align: center;
  display: table;
}

.shipment .doc-premium .no-photo p {
  color: white;
  font-size: xx-large;
  display: table-cell;
  vertical-align: middle;
}

.shipment .doc-premium img {
  width: 300px;
  height: 100%;
  margin: 80px;
}

.shipment .doc-premium span:first-of-type {
  margin-left: 0;
}

span:last-of-type {
  margin-right: 0;
}

.point-record-table-wrapper {
  background: #1c2023;
}

.point-record-table-wrapper table thead {
  background: #343a40;
}

.point-record-table-wrapper table thead tr th {
  color: #ffbb0f;
  font-weight: 300;
  padding: 3px 20px;
  text-align: left;
  font-size: 14px;
}

.point-record-table-wrapper table tbody tr td {
  color: white;
  font-size: 12px;
}

.point-record-table-wrapper table tbody tr.record-shipment td {
  padding: 0;
}
.point-record-table-wrapper table tbody tr.record-shipment td .shipment {
  border-top: unset;
  background: #1c2023;
  margin-left: 60px;
}

.pagination-wrapper {
  position: fixed;
  width: 100%;
  text-align: center;
  background: #343a40;
}

.pagination-wrapper .page-number {
  width: 171px;
  float: left;
}

.pagination-wrapper .page-number p {
  color: #ffbb0f;
  display: inline-block;
  margin: 10px;
}

.pagination-wrapper .page-number input {
  width: 60px;
  display: inline-block;
  background: #343a40;
  border: none;
  color: #ffbb0f;
  padding: 10px;
  outline: none;
}

.pagination-wrapper .page-counter p {
  color: #ffbb0f;
  float: left;
  padding: 10px 0 0 0;
}

.pagination-wrapper .pagination-button {
  display: inline-block;
  padding: 10px 40px;
}

.pagination-wrapper .pagination-rows-btn p {
  color: white;
}

.pagination-wrapper .pagination-btn-wrapper {
  width: 50%;
  margin: 0 auto;
}

.pagination-wrapper .pagination-rows-btn {
  color: #ffbb0f;
  background: #343a40;
  vertical-align: top;
  padding: 5px;
  border: none;
  border-radius: 0;
  float: right;
  margin-top: 4px;
  cursor: pointer;
}

@media only screen and (min-device-width : 768px) {
  .point-record-table-wrapper table{
    width: 100%;
    margin: 0;
  }
}

@media only screen and (min-device-width : 1224px) {
  .point-record-table-wrapper table {
    width: 100%;
    margin: 0;
  }
}
.point-record-view-wrapper {
  background: #1c2023; 
}

.point-record-view-wrapper table tbody tr td {
  text-align: left;
  padding: 5px 17px;
  border-bottom: 1px solid gray;
}

.point-record-view-wrapper table tbody tr td .open {
  color: #ffbb0f;
  font-size: 17px;
  cursor: pointer;
  margin: 0;
}

.point-record-view-wrapper table tbody tr td .opened {
  color: #ffbb0f;
  font-size: 17px;
  cursor: pointer;
  margin: 0;
}

.point-record-view-wrapper table tbody tr td .no-shipments {
  color: #B64EEC;
  font-size: 12px;
  cursor: pointer;
  outline: none;
  margin: 0;
}

.point-record-view-wrapper .with-shipments {
  outline: none;
}

.point-record-view-wrapper .with-shipments .icon {
  display: inline-block;
  color: #ffbb0f;
  outline: none;
  font-size: 18px;
  margin: 3px 10px;
}
.point-record-view-wrapper .with-shipments .icon:hover {
  color: white;
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  .point-record-view-wrapper table tbody tr td {
    padding: 10px;
  }
}
nav {
  background: black;
}

.menu {
  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
  background: black;
}

nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  background: black;
}

nav li a {
  display: block;
  padding: 20px 20px;
  text-decoration: none;
  color: lightgray;
  font-family: 'Reem Kufi', sans-serif;
}

nav li a:hover,
.menu-btn:hover {
  color: #ffbb0f;
  text-decoration: none;
}

.logo {
  display: block;
  float: left;
  padding: 0;
  text-decoration: none;
  width: 8pc;
  margin: 14px;
}

.logo-img {
  width: 100%;
}

/* menu icon */

.menu-icon {
  cursor: pointer;
  float: right;
  padding: 28px 20px;
  position: relative;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.menu-icon .navicon {
  background: #ffbb0f;
  display: block;
  height: 2px;
  position: relative;
  transition: background .2s ease-out;
  width: 18px;
}

.menu-icon .navicon:before,
.menu-icon .navicon:after {
  background:#ffbb0f;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.menu-icon .navicon:before {
  top: 5px;
}

.menu-icon .navicon:after {
  top: -5px;
}

.menu-btn {
  display: none;
}

.menu-btn:checked ~ .menu {
  max-height: 340px;
}

.menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.menu-btn:checked ~ .menu-icon .navicon:before {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.menu-btn:checked ~ .menu-icon .navicon:after {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */

@media (min-width: 840px) {
  .menu {
    padding: 0;
  }
  
  .landing-items li {
    float: left;
  }
  .landing-items li a {
    padding: 20px 8px;
  }
  .menu {
    clear: none;
    float: right;
    max-height: none;
  }
  .menu-icon {
    display: none;
  }
  li a:hover,
  .menu-btn:hover {
    background-color: black;
  }
}

@media (min-width: 768px) {
  nav {
    height: 66px;
  }
}

@media (min-width: 1000px) {
  nav {
    height: 66px;
  }
  .landing-items li a {
    padding: 20px 20px;
  }
}

.carrier-record-table-wrapper table {
  margin: 0 auto 2pc auto;
}

.carrier-record-table-wrapper {
  background: #1c2023;
  position: absolute;
  overflow-y: scroll;
  width: 100%;
}

.carrier-record-table-wrapper .loading {
  width: 100%;
  margin-left: 50%;
  border-bottom: 2px solid #ffbb0f;
}

.carrier-record-table-wrapper table thead {
  background: #343a40;
}

.carrier-record-table-wrapper table thead tr th {
  color: #ffbb0f;
  font-weight: 300;
  font-size: 12px;
  padding: 15px 20px;
  text-align: left;
  vertical-align: top;
}

.carrier-record-table-wrapper table thead tr th .filter-input input {
  background: #343a40;
  border: none;
  border-bottom: 1px solid #ffbb0f;
  outline: none;
  width: 100px;
  color: white;
}

.carrier-record-table-wrapper table tbody tr td {
  color: white;
  font-size: 12px;
  padding: 10px 20px;
  border-bottom: 1px solid #343a40;
}

.carrier-record-table-wrapper table {
  margin: 0 auto 2pc auto;
}

.carrier-record-table-wrapper {
  background: #1c2023;
  overflow-y: auto;
  width: 100%;
  height: calc(100vh - 60px);
}

.carrier-record-table-wrapper .loading {
  width: 100%;
  margin-left: 50%;
  border-bottom: 2px solid #ffbb0f;
}

.carrier-record-table-wrapper table thead {
  background: #343a40;
}

.carrier-record-table-wrapper table thead tr th {
  color: #ffbb0f;
  font-weight: 300;
  font-size: 12px;
  padding: 15px 20px;
  text-align: left;
  vertical-align: top;
}

.carrier-record-table-wrapper table thead tr th .filter-input input {
  background: #343a40;
  border: none;
  border-bottom: 1px solid #ffbb0f;
  outline: none;
  width: 100px;
  color: white;
}

.carrier-record-table-wrapper table tbody tr td {
  color: white;
  font-size: 12px;
  padding: 10px 20px;
  border-bottom: 1px solid #343a40;
}

.carrier-record-billing-table-wrapper {
  background: #1c2023;
  width: 100%;
  padding-bottom: 45px;
}

.carrier-record-billing-table-wrapper table {
  width: 100%;
}

.carrier-record-billing-table-wrapper table thead {
  background: #343a40;
  color: gray;
}

.carrier-record-billing-table-wrapper table thead tr th {
  color: gray;
  font-weight: 400;
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
}

.carrier-record-billing-table-wrapper table tbody tr td {
  color: white;
  font-size: 12px;
}

.carrier-record-billing-table-wrapper table tbody tr.record-shipment td {
  padding: 0;
  
}
.carrier-record-billing-table-wrapper table tbody tr.record-shipment td .shipment {
  border-top: unset;
  background: #1c2023;
  margin-left: 60px;
}
.carrier-record-billing-view-wrapper {
  overflow-y: auto;
  width: 100%;
}

.carrier-record-billing-view-wrapper .file-download-icon {
  margin: 0 10px;
}

.carrier-record-billing-view-wrapper table tbody tr td {
  background: #1c2023;
}

.carrier-record-billing-view-wrapper table tbody tr td {
  text-align: left;
  padding: 5px 17px 5px 0;
  border-bottom: 1px solid gray;
}

.carrier-record-billing-view-wrapper table tbody tr td .open {
  color: #ffbb0f;
  font-size: 14px;
  text-align: right;
  cursor: pointer;
  margin: 0;
}

.carrier-record-billing-view-wrapper table tbody tr td .opened {
  color: #ffbb0f;
  font-size: 14px;
  text-align: right;
  cursor: pointer;
  margin: 0;
}

.settlement-shipment-header {
  padding: 5px 10px 0;
  border-bottom: 1px solid black;
}

.settlement-shipment .amounts, 
.settlement-shipment-header .amounts {
  text-align: right;
}

.settlement-shipment-header p {
  display: inline-block;
  padding: 0 10px;
  color: #ffbb0f;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.settlement-shipment {
  padding: 2px 10px;
  border-bottom: 1px solid black;
}

.settlement-shipment p {
  display: inline-block;
  padding: 0 10px;
  color: #a9a9a9;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: bottom;
}

.settlement-shipment-header p.fit-lg-content,
.settlement-shipment p.fit-lg-content {
  width: 12pc;
}

.settlement-shipment-header p.fit-md-content,
.settlement-shipment p.fit-md-content {
  width: 7pc;
}

.settlement-shipment-header p.fit-sm-content,
.settlement-shipment p.fit-sm-content {
  width: 5pc;
}

.carrier-record-billing-table-wrapper table {
  width: 130pc;
}

.carrier-record-billing-view-wrapper table tbody tr td .documents-icon {
  color: #6495ed;
  cursor: pointer;
}

.carrier-record-billing-table-wrapper .doc-settlements {
  z-index: 1000;
  position: absolute;
  top: 84px;
  left: 5%;
  width: 90%;
  height: 85vh;
  background: #1c2023;
  overflow: auto;
  border-radius: 20px;
  border: 1px solid #ffbb0f;
}

.carrier-record-billing-table-wrapper .doc-settlements h3 {
  color: #ffbb0f;
  padding: 3px 23px;
  cursor: pointer;
  text-align: right;
  font-weight: 300px;
}

.carrier-record-billing-table-wrapper .pdf-list-wrapper {
  background: white;
  overflow: auto;
  margin: 0 3%;
  height: 74vh;
}

.carrier-record-billing-table-wrapper .pdf-list-wrapper p {
  width: 100%;
  background: #343a40;
  color: #ffbb0f;
  padding: 6px 10px;
}

.carrier-record-billing-table-wrapper .settlement-wrap {
  height: 36pc;
  overflow: auto;
}

.carrier-record-billing-table-wrapper .pdf-list-wrapper .pdf-icon {
  margin: 0 5px;
  color: red
}
.verify-account-wrapper {
  background: black;
  padding: 5px;
  margin: 4px 10px;
  cursor: pointer;
  color: white;
  border-spacing: 0;
}

.password-reset-wrapper {
  background: black;
}
.password-reset-wrapper .title p {
  color: lightseagreen;
  font-size: 14;
}

