.point-record-view-wrapper {
  background: #1c2023; 
}

.point-record-view-wrapper table tbody tr td {
  text-align: left;
  padding: 5px 17px;
  border-bottom: 1px solid gray;
}

.point-record-view-wrapper table tbody tr td .open {
  color: #ffbb0f;
  font-size: 17px;
  cursor: pointer;
  margin: 0;
}

.point-record-view-wrapper table tbody tr td .opened {
  color: #ffbb0f;
  font-size: 17px;
  cursor: pointer;
  margin: 0;
}

.point-record-view-wrapper table tbody tr td .no-shipments {
  color: #B64EEC;
  font-size: 12px;
  cursor: pointer;
  outline: none;
  margin: 0;
}

.point-record-view-wrapper .with-shipments {
  outline: none;
}

.point-record-view-wrapper .with-shipments .icon {
  display: inline-block;
  color: #ffbb0f;
  outline: none;
  font-size: 18px;
  margin: 3px 10px;
}
.point-record-view-wrapper .with-shipments .icon:hover {
  color: white;
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  .point-record-view-wrapper table tbody tr td {
    padding: 10px;
  }
}