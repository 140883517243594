.point-record-table-wrapper {
  background: #1c2023;
}

.point-record-table-wrapper table thead {
  background: #343a40;
}

.point-record-table-wrapper table thead tr th {
  color: #ffbb0f;
  font-weight: 300;
  padding: 3px 20px;
  text-align: left;
  font-size: 14px;
}

.point-record-table-wrapper table tbody tr td {
  color: white;
  font-size: 12px;
}

.point-record-table-wrapper table tbody tr.record-shipment td {
  padding: 0;
}
.point-record-table-wrapper table tbody tr.record-shipment td .shipment {
  border-top: unset;
  background: #1c2023;
  margin-left: 60px;
}

.pagination-wrapper {
  position: fixed;
  width: 100%;
  text-align: center;
  background: #343a40;
}

.pagination-wrapper .page-number {
  width: 171px;
  float: left;
}

.pagination-wrapper .page-number p {
  color: #ffbb0f;
  display: inline-block;
  margin: 10px;
}

.pagination-wrapper .page-number input {
  width: 60px;
  display: inline-block;
  background: #343a40;
  border: none;
  color: #ffbb0f;
  padding: 10px;
  outline: none;
}

.pagination-wrapper .page-counter p {
  color: #ffbb0f;
  float: left;
  padding: 10px 0 0 0;
}

.pagination-wrapper .pagination-button {
  display: inline-block;
  padding: 10px 40px;
}

.pagination-wrapper .pagination-rows-btn p {
  color: white;
}

.pagination-wrapper .pagination-btn-wrapper {
  width: 50%;
  margin: 0 auto;
}

.pagination-wrapper .pagination-rows-btn {
  color: #ffbb0f;
  background: #343a40;
  vertical-align: top;
  padding: 5px;
  border: none;
  border-radius: 0;
  float: right;
  margin-top: 4px;
  cursor: pointer;
}

@media only screen and (min-device-width : 768px) {
  .point-record-table-wrapper table{
    width: 100%;
    margin: 0;
  }
}

@media only screen and (min-device-width : 1224px) {
  .point-record-table-wrapper table {
    width: 100%;
    margin: 0;
  }
}