@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  from {
    bottom: 4pc;
  }

  to {
    bottom: 5pc;
  }
}

.landing-page {
  background: #1c2023;
  font-family: 'Open Sans', sans-serif;
}

.landing-page .section-one {
  background: url('./img/DSC_1329_rotada.jpg') fixed;
  background-position: center bottom;
  height: 10pc;
  animation: fadeIn 5s;
}

.landing-page .landing-logo {
  float: right;
  width: 140px;
  margin: 20px 30px;
}

.landing-page .phrase-wrapper {
  position: absolute;
  margin: 5pc 1pc 0 1pc;
  right: 0;
  text-align: right;
  animation: fadeIn 9s;
}

.landing-page h1.phrase {
  font-family: 'Merriweather Sans', sans-serif;
  color: white;
  font-size: 24px;
}

.landing-page h2.sub-phrase {
  font-family: 'Merriweather Sans', sans-serif;
  color: white;
  font-size: 14px;
}

.landing-page .arrow-wrapper {
  display: none;
}

.landing-page .arrow-icon {
  position: absolute;
  color: white;
  font-size: 40px;
  bottom: 6pc;
}

.landing-page .humber-btn-wrapper {
  position: absolute;
  width: 200px;
  margin: 10pc 1pc 0 1pc;
  right: 0;
}

.landing-page .humber-btn {
  width: 164px;
  cursor: pointer;
  float: right;
  text-align: center;
  padding: 7px;
  border: 1px solid #ffbb0f;
  color: white;
  margin: 8px;
  border-radius: 50px;
  outline: none;
}

.landing-page .login-landing-wrapper {
  width: 100%;
  margin: 0 auto;
}

.landing-page .section-two .phrase {
  text-align: center;
  padding: 20px;
  background: #1c2023;
  color: #ffbb0f;
}

.landing-page .items h4 {
  color: #ffbb0f;
  font-size: 20px;
  text-align: center;
}

.landing-page .items {
  padding: 10px;
  color: #1c2023;
  background: white;
  font-size: 24px;
}

.landing-page .items p {
  font-size: 16px;
  color: #1c2023;
  text-align: center;
}

.landing-page .items .item {
  padding: 25px;
  width: 97%;
  margin: 28px auto;
  height: 290px;
}

.landing-page .section-two .truck-img {
  display: none;
}

.landing-page .items .item .img-wrapper {
  width: 120px;
  margin: 0 auto;
}

.landing-page .items .item .img-wrapper img {
  width: 100%;
  padding: 18px 0;
}

.landing-page .section-three .phrase {
  text-align: center;
  padding: 20px;
  color: #ffbb0f;
}

.landing-page .section-three .img-wrapper {
  width: 200px;
  margin: 0 auto;
}

.landing-page .section-three .img-wrapper img {
  width: 100%;
}

.landing-page .section-three .phrase h3 {
  color: #ffbb0f;
}

.landing-page .section-three .item-icon {
  display: inline-block;
  width: 10%;
  color: #ffbb0f;
  vertical-align: top;
  margin-top: 42px;
}

.landing-page .section-three .user-type {
  background: #ffbb0f;
  color: gray;
  text-align: center;
  padding: 12px;
  font-size: 20px;
}

.landing-page .section-three .shipper-section p,
.landing-page .section-three .carrier-section p {
  display: inline-block;
  width: 90%;
  color: #1c2023;
  text-align: left;
  margin: 40px 0;
}

.landing-page .footer {
  height: 200px;
}

.landing-page .footer p {
  margin-top: 36px;
}

.landing-page .footer .footer-logo-wrapper {
  width: 180px;
  margin: 20px auto;
}

.landing-page .section-three .laptop-img {
  display: none;
}

.landing-page .register-items {
  display: none;
}

.landing-page .landing-sign-up-title-wrapper {
  background: url('./img/registrate.jpg') fixed;
  background-size: cover;
  background-position: center top;
  height: 4pc;
  animation: fadeIn 5s;
  text-align: center;
}

.landing-page .landing-sign-up-title-wrapper h2 {
  padding-top: 10px;
}

.landing-page .option {
  display: inline-block;
  font-size: 14px;
  border-radius: 50px;
  cursor: pointer;
  color: #ffbb0f;
  padding: 14px;
  width: 40%;
  margin: 0 10px;
  text-align: center;
  outline: none;
  border: 1px solid;
}

.landing-page .option-selected {
  background: #ffbb0f;
  padding-right: 15px;
  color: gray;
  font-size: 14px;
  display: inline-block;
  border-radius: 50px;
  cursor: pointer;
  padding: 14px;
  width: 40%;
  margin: 0 10px;
  text-align: center;
  outline: none;
}

.landing-page .section-three .landing-buttons-wrapper {
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
}

.landing-page .section-three .landing-buttons-wrapper .landing-btn {
  display: inline-block;
  border-radius: 50px;
  cursor: pointer;
  color: #ffbb0f;
  padding: 14px;
  width: 30%;
  margin: 0 10px;
  text-align: center;
  outline: none;
  border: 1px solid;
}

.landing-page .hidden-input {
  visibility: hidden;
}

.landing-page .humber-btn-list {
  padding: 10px;
  display: inline;
}

@font-face {
  font-family: ETmodules;
  font-display: block;
  src: url(/fonts/modules.eot);
  src: url(/fonts/modules.eot?#iefix) format("embedded-opentype"), url(/fonts/modules.ttf) format("truetype"), url(/fonts/modules.ttf) format("woff"), url(/fonts/modules.svg#ETmodules) format("svg");
  font-weight: 400;
  font-style: normal
}

#et-info-phone:before {
  content: "\e090";
  position: relative;
  margin-right: 4px;
  font-family: ETmodules !important;
  font-style: normal;
  font-weight: 400;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 0 0;
  direction: ltr;
  top: 2px;
}

#et-info-mail:before {
  content: "\e076";
  position: relative;
  margin-right: 4px;
  font-family: ETmodules !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 0 0;
  direction: ltr
}

.phoneNumber {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.landing-page .footer p {
  color: #ffbb0f;
}

@media (min-width: 768px) {
  .landing-page .login-landing-wrapper {
    position: absolute;
    top: 9pc;
  }

  .landing-page .sign-up-landing-wrapper {
    background: url('./img/bgRegister.jpg') fixed;
    background-size: cover;
    background-position: center top;
    animation: fadeIn 5s;
  }

  .landing-page .section-one {
    background: url('./img/DSC_1329_rotada.jpg') fixed;
    background-size: cover;
    background-position: center;
    height: 62pc;
  }

  .landing-page .section-two {
    background: white;
  }

  .landing-page .section-three {
    background: white;
  }

  .landing-page .section-three .items {
    width: 45%;
    display: inline-block;
    padding: 30px;
  }

  .landing-page .section-three .item-icon {
    width: 6%;
    margin-right: 10px;
  }

  .landing-page .section-three .shipper-section {
    width: 95%;
    margin: 0 auto;
  }

  .landing-page .section-three .shipper-section p,
  .landing-page .section-three .carrier-section p {
    width: 90%;
  }

  .landing-page .section-three .laptop-img {
    display: block;
    width: 44%;
    float: right;
    padding-top: 12pc;
  }

  .landing-page .section-three .laptop-img img {
    width: 100%;
  }

  .landing-page .landing-logo {
    float: left;
    width: 120px;
    margin: 20px 30px;
  }

  .landing-page .humber-btn-wrapper {
    position: absolute;
    width: 42pc;
    margin: 20px 13pc 0 0;
    text-align: center;
  }

  .landing-page .arrow-wrapper {
    display: block;
    cursor: pointer;
    bottom: 10px;
    position: absolute;
    width: 100%;
    text-align: center;
  }

  .landing-page .items {
    background: transparent;
  }

  .landing-page .phrase-wrapper {
    width: 100%;
    text-align: center;
  }

  .landing-page .section-three .content {
    width: 48pc;
    margin: 0 auto;
  }

  .landing-page .section-three .user-type {
    color: gray;
    background: transparent;
    text-align: center;
    padding: 12px;
    font-size: 5pc;
  }
}

@media only screen and (min-width : 1224px) {
  .landing-page .phrase-wrapper {
    width: 50%;
    text-align: right;
    left: 0;
    top: 10pc;
  }

  .landing-page .login-landing-wrapper {
    right: 0;
    padding-top: 0;
    width: 50%;
  }

  .landing-page .section-two {
    background: url('./img/bgPorque.jpg') fixed -1000px;
    background-size: cover;
    background-position: center;
    height: 62pc;
  }

  .landing-page .section-two {
    margin: 0 auto;
  }

  .landing-page .section-two h1 {
    font-size: 50px;
    font-weight: bolder;
    width: 100%;
    text-align: center;
  }

  .landing-page .items {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding-top: 100px;
  }

  .landing-page .items .item {
    display: inline-grid;
    border-top-right-radius: 46px;
    border-bottom-left-radius: 72px;
    margin: 10px;
    height: 400px;
    background: white;
    width: 270px;
    box-shadow: -8px 5px 10px #888888;
  }

  .landing-page .items .item .img-wrapper {
    width: 120px;
    margin: 0 auto;
  }

  .landing-page .items .item .img-wrapper img {
    width: 100%;
    padding: 18px 0;
  }

  .landing-page .items .item h4 {
    height: 41px;
    text-align: center;
    padding: 10px;
    font-size: 22px;
  }

  .landing-page .items .item p {
    color: gray;
  }

  .landing-page .humber-btn-desktop {
    display: block;
  }

  .landing-page .humber-btn {
    display: none;
  }

  .landing-page h2.sub-phrase,
  .landing-page h1.phrase {
    font-size: 40px;
  }

  .landing-page .register-items {
    display: block;
    width: 48%;
    padding-top: 12pc;
    float: right;
  }

  .landing-page .register-items p {
    color: white;
    display: inline-block;
    width: 90%;
    font-size: 22px;
  }

  .landing-page .register-items .item-icon {
    color: #B64DEB;
    display: inline-block;
    width: 10%;
    font-size: 20px;
  }

  .landing-page .option,
  .landing-page .option-selected {
    width: 30%;
  }
}

@media only screen and (min-width : 1824px) {
  .landing-page .section-one h1.phrase {
    font-size: 50px;
    width: 45pc;
  }

  .landing-page .section-one h2.sub-phrase {
    font-size: 50px;
    width: 45pc;
  }

  .landing-page .items {
    padding-top: 9pc;
  }
}